import {StringTranslation} from "../../utils/StringTranslation";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";
import {
    useGetAuthSettingsQuery, useLazyGetAuthSettingsQuery,
    useSetAuthMethodMutation
} from "../../../pages/accountPage/AccountSlice";
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {useHistory} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import ReactInputVerificationCode from "react-input-verification-code";
import {BarLoader} from "react-spinners";

interface Props {
    selectedNewMethod: string | null
    authCodeInput: string
    authMethodIsSet: boolean
    setAuthCodeInput: (code: string) => void
    setAuthMethodIsSet: (isSet: boolean) => void
}

export const SettingsAuthenticationInput = (props: Props) => {

    let history = useHistory()
    const userData = useGetUserInfoQuery()

    const {data: translations, isLoading: isTranslationsLoading} = useGetTranslationsQuery();
    const {data: authSettings} = useGetAuthSettingsQuery();

    const [authSettingsTrigger] = useLazyGetAuthSettingsQuery()
    const [setAuthMethodTrigger, setAuthMethodResult] = useSetAuthMethodMutation()

    const {selectedNewMethod, authCodeInput, authMethodIsSet, setAuthCodeInput, setAuthMethodIsSet} = props

    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {authentication_methods} = authSettings || {
        authentication_methods: [],
        current_authentication_method: ''
    }

    const translationsLoaded = !isTranslationsLoading && !!activeLanguage;
    const selectedMethod = authentication_methods.find(method => method.authentication_method === selectedNewMethod)

    const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
        return error && typeof error.status !== 'undefined';
    };

    const hasMessage = (data: any): data is { message: string } => {
        return data && typeof data.message === 'string';
    };

    return (
        <>
            {translationsLoaded ?
                <label>{StringTranslation('authentication-code', translations, activeLanguage)}:</label>
                :
                <label className="skeleton skeleton-title"></label>
            }
            {!!selectedMethod &&
                <div className="c-account-content__settings-container__form">
                    <ReactInputVerificationCode
                        length={6}
                        type="text"
                        value={authCodeInput}
                        onChange={(code) => {
                            setAuthCodeInput(code as any);
                        }}
                        autoFocus
                        placeholder=""
                    />
                    <button className="c-btn c-btn--primary" disabled={setAuthMethodResult.isLoading || !authCodeInput}
                            onClick={() => {
                                setAuthMethodIsSet(true)
                                setAuthMethodTrigger({
                                    authentication_method: selectedMethod.authentication_method,
                                    code: authCodeInput
                                }).then((result: any) => {
                                    if (!!result.error) {
                                        setAuthCodeInput('')
                                        return
                                    }
                                    authSettingsTrigger()
                                    history.push(RouteEnum.PRODUCT_RANGE)
                                })
                            }}>
                        {(setAuthMethodResult.isLoading) ? (
                            <div className={'c-loader'}>
                                <BarLoader color={'#000000'}/>
                            </div>
                        ) : (
                            <>
                                {translationsLoaded ?
                                    <span>{StringTranslation('activate', translations, activeLanguage)}</span>
                                    :
                                    <span className="skeleton skeleton-name"></span>
                                }
                            </>
                        )}
                    </button>
                </div>
            }
            {authMethodIsSet && !setAuthMethodResult.isLoading && isFetchBaseQueryError(setAuthMethodResult?.error) && hasMessage(setAuthMethodResult.error.data) &&
                <>
                    <br/> <p style={{
                    marginTop: '1em',
                    fontWeight: 'bold',
                    fontSize: '1.8rem'
                }}>{setAuthMethodResult.error.data?.message}</p>
                </>
            }

        </>
    )
}

